/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import coreInstance from "../../services/core"
import cicsInstance from "../../services/cics"
import ggcsInstance from "../../services/ggcs"

export default {
  SET_BEARER(state, accessToken) {
    coreInstance.defaults.headers.common['x-access-token'] = accessToken
    cicsInstance.defaults.headers.common['x-access-token'] = accessToken
    ggcsInstance.defaults.headers.common['x-access-token'] = accessToken
  }
}
