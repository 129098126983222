/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/services/core"
import axiosBase from 'axios'

const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
    // eslint-disable-next-line
    fetchCitiesByState({ commit }, state) {
      return new Promise((resolve, reject) => {
        axios.get("rm/fetchCities?state="+state)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {reject(error)})
      })
    },
    // eslint-disable-next-line
    registerUser({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        axios.post("indicator/create", userInfo)
          .then(response => {
            resolve(response)
          })
          .catch((error) => {reject(error)})
      });
    },
    // eslint-disable-next-line
    checkCPF({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("rm/checkCPF", payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {reject(error)})
      })
		},
		// /////////////////////////////////////////////
    // System/ CEP
    // /////////////////////////////////////////////
    // eslint-disable-next-line
		fetchCEP({ commit }, cep) {
			return new Promise((resolve, reject) => {
				axiosBase.get("https://viacep.com.br/ws/" + cep +  "/json")
					.then((response) => {
						resolve(response)
					})
					.catch((error) => { reject(error) })
			})
		},
}

export default actions
