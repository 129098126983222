/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
// const userDefaults = {
//   uid         : 0,          // From Auth
//   displayName : "John Doe", // From Auth
//   about       : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
//   photoURL    : require("@/assets/images/portrait/small/avatar-s-11.jpg"), // From Auth
//   status      : "",
//   userRole    : "admin"
// }

const userDefaults = {
//  uid         : 0,          // From Auth
  displayName : "John Doe", // From Auth
//  about       : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  photoURL    : require("@/assets/images/portrait/small/avatar-s-11.jpg"), // From Auth
//  status      : "",
//  userRole    : "admin"
}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function(query) {
      return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || window.DocumentTouch) {
      return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

//UF 
const UF = [
  {"name": "Acre", "initials": "AC"},
  {"name": "Alagoas", "initials": "AL"},
  {"name": "Amapá", "initials": "AP"},
  {"name": "Amazonas", "initials": "AM"},
  {"name": "Bahia", "initials": "BA"},
  {"name": "Ceará", "initials": "CE"},
  {"name": "Distrito Federal", "initials": "DF"},
  {"name": "Espírito Santo", "initials": "ES"},
  {"name": "Goiás", "initials": "GO"},
  {"name": "Maranhão", "initials": "MA"},
  {"name": "Mato Grosso", "initials": "MT"},
  {"name": "Mato Grosso do Sul", "initials": "MS"},
  {"name": "Minas Gerais", "initials": "MG"},
  {"name": "Pará", "initials": "PA"},
  {"name": "Paraíba", "initials": "PB"},
  {"name": "Paraná", "initials": "PR"},
  {"name": "Pernambuco", "initials": "PE"},
  {"name": "Piauí", "initials": "PI"},
  {"name": "Rio de Janeiro", "initials": "RJ"},
  {"name": "Rio Grande do Norte", "initials": "RN"},
  {"name": "Rio Grande do Sul", "initials": "RS"},
  {"name": "Rondônia", "initials": "RO"},
  {"name": "Roraima", "initials": "RR"},
  {"name": "Santa Catarina", "initials": "SC"},
  {"name": "São Paulo", "initials": "SP"},
  {"name": "Sergipe", "initials": "SE"},
  {"name": "Tocantins", "initials": "TO"}
]



// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser           : userDefaults,
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    is_touch_device         : is_touch_device(),
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,
    

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    UF: UF
}

export default state
