/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '/admin/',
      component: () => import('./layouts/main/Main.vue'),
      meta: {
        authRequired: true
      },
    },
    {
      // =============================================================================
      // MAIN GGCS LAYOUT ROUTES
      // =============================================================================
      path: '/admin/ggcs',
      component: () => import('./layouts/main/Main.vue'),
      meta: {
        authRequired: true
      },
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: 'buffets/agenda',
          name: 'ggcs-buffets',
          component: () => import('./views/ggcs/buffets/calendar/Agenda.vue'),
          meta: {
            pageTitle: "Agenda Refeitório",
            authRequired: true,
          }
        },
        {
          path: 'buffets/plates',
          name: 'ggcs-buffets',
          component: () => import('./views/ggcs/buffets/plates/List.vue'),
          meta: {
            pageTitle: "Pratos",
            authRequired: true,
          }
        },
        {
          path: 'buffets/orders-today',
          name: 'ggcs-buffets',
          component: () => import('./views/ggcs/buffets/orders/OrdersToday.vue'),
          meta: {
            pageTitle: "Pedidos do Dia",
            authRequired: true,
          }
        },
        {
          path: 'news',
          name: 'ggcs-news',
          component: () => import('./views/ggcs/news/List.vue'),
          meta: {
            pageTitle: "Notícias",
            authRequired: true,
          }
        },
        {
          path: 'pushes',
          name: 'ggcs-pushes',
          component: () => import('./views/ggcs/Pushes.vue'),
          meta: {
            pageTitle: "Notificações",
            authRequired: true,
          }
        }
        // {
        //   path: 'map',
        //   name: 'map',
        //   //redirect: 'indicar',
        //   component: () => import('./views/pages/GoogleMap.vue'),
        //   meta: {
        //     pageTitle: 'Mapa de Condições Inseguras',
        //     authRequired: true
        //   }
        // },
        // {
        //   path: 'indications',
        //   name: 'indications',
        //   alias: 'atendimentos',
        //   component: () => import('./views/insecure-condition/List.vue'),
        //   meta: {
        //     pageTitle: 'Lista Atendimentos Condições Inseguras',
        //     authRequired: true
        //   },
        // },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/',
          name: 'page-login',
          alias: 'login',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            mustBeDislogged: true,
          }
        },
        // {
        //   path: '/',
        //   name: 'page-sign-up',
        //   alias: 'home',
        //   component: () => import('@/views/pages/Login.vue'),
        //   meta: {
        //     mustBeDislogged: true,
        //   }
        // },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')

  if (appLoading) {
    appLoading.style.display = "none";
  }
})

router.beforeEach((to, from, next) => {
  if (store.state.auth.isUserLoggedIn()) {
    if (to.meta.mustBeDislogged) {
      router.push({ path: '/admin' })
    }
  } else if (to.meta.authRequired) {
    router.push({ path: '/admin/login', query: { to: to.path } })
  }
  return next()
});

export default router
